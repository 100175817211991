import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  exploreSlides = [
    "/assets/screens/1_explore/S2.jpg",
    "/assets/screens/1_explore/S3.jpg",
    "/assets/screens/1_explore/S4.jpg",
    "/assets/screens/1_explore/S5.jpg",
    "/assets/screens/1_explore/S6.jpg",
    "/assets/screens/1_explore/S7.jpg",
    "/assets/screens/1_explore/S8.jpg",
    "/assets/screens/1_explore/S9.jpg",
  ];

  aiSlides = [
    "/assets/screens/1_AI/AI1.jpg",
    "/assets/screens/1_AI/AI2.jpg",
    "/assets/screens/1_AI/AI3.jpg",
    "/assets/screens/1_AI/AI4.jpg",
    "/assets/screens/1_AI/AI5.jpg",
    "/assets/screens/1_AI/AI6.jpg",
  ];

  searchSlides = [
    "/assets/screens/2_search/S1.jpg",
    "/assets/screens/2_search/S2.jpg",
    "/assets/screens/2_search/S3.jpg",
  ];

  createSlides = [
    "/assets/screens/3_create/S1.jpg",
    "/assets/screens/3_create/S2.jpg",
    "/assets/screens/3_create/S3.jpg",
    "/assets/screens/3_create/S4.jpg",
    "/assets/screens/3_create/S5.jpg",
  ];

  lightingSlides = [
    "/assets/screens/4_lighting/S1.jpg",
    "/assets/screens/4_lighting/S2.jpg",
    "/assets/screens/4_lighting/S3.jpg",
    "/assets/screens/4_lighting/S4.jpg",
    "/assets/screens/4_lighting/S5.jpg",
    "/assets/screens/4_lighting/S6.jpg",
    "/assets/screens/4_lighting/S7.jpg",
    "/assets/screens/4_lighting/S8.jpg",
    "/assets/screens/4_lighting/S9.jpg",
    "/assets/screens/4_lighting/S10.jpg",
  ];

  weatherSlides = [
    "/assets/screens/5_weather/S1.jpg",
  ];

  constructor(private firebase: FirebaseService,) { }

  ngOnInit(): void {
    this.getSettings();
  }

  signUpForEarlyAcess() {
    window.open('https://forms.gle/9vq1MqGpEF5cpD5U7', "_blank");
  }

  onAppStoreOpen() {
    this.pinterestAddToCart();
    this.googleAnalyticsOpenStore('app');

    return true;
  }

  onPlayStoreOpen() {
    this.pinterestAddToCart();
    this.googleAnalyticsOpenStore('play');

    return true;
  }

  pinterestAddToCart() {
    try {
      // @ts-ignore
      pintrk('track', 'addtocart', {
        value: 1.00,
        order_quantity: 1,
        currency: 'USD'
      });
    } catch (e) {
      console.error(e);
    }
  }

  googleAnalyticsOpenStore(store: string) {
    try {
      this.firebase.analytics().logEvent(`open_${store}_store`);
    } catch (e) {
      console.error(e);
    }
  }

  posesAll = 6000;
  posesFree = 1300;
  setupsAll = 40;
  setupsFree = 8;

  async getSettings() {
    try {
      const doc = (await this.firebase.firestore().collection("settings")
        .doc("content").get()) as any;
      this.posesAll = doc.data().posesAll;
      this.posesFree = doc.data().posesFree;
      this.setupsAll = doc.data().setupsAll;
      this.setupsFree = doc.data().setupsFree;
    } catch (e) {
      console.error(e);
    }
  }
}
