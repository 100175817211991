<mat-card>
    <mat-progress-spinner *ngIf='loading' mode="indeterminate"></mat-progress-spinner>
    <div class="flex-row">
        <div class="with-padding">
            <mat-form-field class="text-field">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search" [matAutocomplete]="auto" [formControl]="searchAutocompleteCtrl">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngIf="isAutocompleteLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isAutocompleteLoading">
                        <mat-option *ngFor="let option of options" [value]="option">
                            <span>{{option}}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="with-padding">
            <button mat-raised-button color="primary" (click)='search()'>
                Search
            </button>
            <button mat-button [matMenuTriggerFor]="collectionCategoriesMenu">Add to...</button>
            <mat-menu #collectionCategoriesMenu="matMenu">
                <ng-container *ngFor="let collectionCategory of collectionCategories">
                    <button class="menuItem" mat-menu-item [matMenuTriggerFor]="sub_menu">{{ collectionCategory.category
                        }}</button>
                    <mat-menu #sub_menu="matMenu">
                        <ng-container *ngFor="let collection of getCollectionsByCategory(collectionCategory.category)">
                            <button class="menuItem" mat-menu-item (click)='addToCollection(collection)'>
                                {{ collection.name }}({{collection.poses.length}}){{collection.isAI ? ' *AI*' : ''}}{{collection.isArtisanAngle ? ' *Artisian*' : ''}}
                            </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </mat-menu>
        </div>
    </div>
    <div *ngIf='poses'>
        <div>
            {{poses.length}} poses found
        </div>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = OnPageChange($event)">
        </mat-paginator>
        <div *ngFor='let pose of pagedPoses'>
            <div class="flex-row">
                <div>
                    <mat-checkbox [checked]="pose.isChecked" (change)="checkChanged($event, pose)">
                    </mat-checkbox>
                </div>
                <div class="img-box">
                    <img [src]='pose.path | getDownloadUrl | async' />
                </div>
                <div class="with-padding">
                    <div>Path: <b>{{pose.path}}</b></div>
                    <div>Folder(bucket): <b>{{pose.imgFolder}}</b>, Id: <b>{{pose.id}}</b>, Score: <b>{{pose.score}}</b>
                    </div>
                    <mat-form-field class="text-field">
                        <mat-label>Description</mat-label>
                        <input matInput [value]="pose.description" (input)="onDescriptionChangeEvent($event, pose)" />
                    </mat-form-field>
                    <div class="flex-row">
                        <button mat-raised-button color="primary" (click)='save(pose)'>
                            Save
                        </button>
                        <button mat-raised-button color="warn" (click)='delete(pose)'>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>