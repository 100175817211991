import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    constructor() {
        var firebaseConfig = {
            apiKey: "AIzaSyCHqjzCn0E19c2cZeeNN6UuVlPDEMIIShg",
            authDomain: "photoposesmobileapp.firebaseapp.com",
            projectId: "photoposesmobileapp",
            storageBucket: "photoposesmobileapp.appspot.com",
            messagingSenderId: "602039037726",
            appId: "1:602039037726:web:0fac9afaf294292e60630f",
            measurementId: "G-G94WT4R1WV"
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
    }

    firestore() {
        return firebase.firestore();
    }

    auth() {
        return firebase.auth();
    }

    storage() {
        return firebase.storage();
    }

    functions() {
        return firebase.functions();
    }

    analytics() {
        return firebase.analytics();
    }
}