<mat-card>
    <mat-progress-spinner *ngIf='loading' mode="indeterminate"></mat-progress-spinner>
    <p>Name: <b>{{collection?.name}}</b>, Featured: <b>{{!!collection?.isFeatured}}</b>, New: <b>{{!!collection?.isNew}}</b>, Category: <b>{{collection?.category}}</b>, Count: <b>{{collection?.poses?.length}}</b></p>
    <button mat-raised-button color="primary" (click)='shuffle()'>
        Shuffle
    </button>
    <mat-grid-list cols="3">
        <mat-grid-tile *ngFor='let pose of collection.poses'>
            <div class='tile'>
                <div class="img-box">
                    <img [src]='pose.path | getDownloadUrl | async' />
                </div>
                <p class='center'>
                    Id: <b>{{pose.id}}</b>, Path: <b>{{pose.path}}</b>
                </p>
                <p class='center'>
                    <button mat-raised-button color="primary" (click)='delete(pose)'>
                        Delete
                    </button>
                    <button mat-raised-button color="primary" (click)='up(pose)'>
                        Up
                    </button>
                    <button mat-raised-button color="primary" (click)='up10(pose)'>
                        Up 10
                    </button>
                    <button mat-raised-button color="primary" (click)='down(pose)'>
                        Down
                    </button>
                    <button mat-raised-button color="primary" (click)='down10(pose)'>
                        Down 10
                    </button>
                    <button mat-raised-button color="primary" (click)='first(pose)'>
                        First
                    </button>
                    <button mat-raised-button color="secondary" (click)='toggleFree(pose.id)'>
                            {{isFree(pose.id) ? "Free" : "Premium"}}
                    </button>
                </p>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</mat-card>