import { Component, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { UntypedFormControl } from '@angular/forms';

import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { FirebaseService } from '../services/firebase.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  loading: boolean = false;
  poses: any[] = null;
  collectionCategories: any[] = [];
  collections: any[] = [];

  pagedPoses: any[];
  length: number = 0;
  pageSize: number = 100;
  pageSizeOptions: number[] = [10, 50, 100, 250, 500];
  pageEvent: PageEvent;

  searchAutocompleteCtrl = new UntypedFormControl();
  isAutocompleteLoading = false;
  options: string[];

  constructor(private firebase: FirebaseService) { }

  ngOnInit(): void {
    this.firebase.firestore().collection("collectionCategories").get()
      .then(categories => this.collectionCategories = categories.docs.map(c => c.data()));

    this.firebase.firestore().collection("collections").get()
      .then(collections => this.collections = collections.docs.map(c => c.data()).sort((a, b) => a.name.localeCompare(b.name)));

    this.searchAutocompleteCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.options = [];
          this.isAutocompleteLoading = true;
        }),
        switchMap(value => this.firebase.functions().httpsCallable('getSuggestions')({ searchText: value })
          .finally(
            () => {
              this.isAutocompleteLoading = false
            }
          )
        )
      )
      .subscribe(data => {
        if (!data) {
          this.options = [];
        } else {
          this.options = data.data;
        }

        console.log(JSON.stringify(data));
      });
  }

  getCollectionsByCategory(category: string) {
    return this.collections.filter(c => c.category == category);
  }

  async addToCollection(collection) {
    try {
      let selectedPoses = this.poses.filter(p => p.isChecked);
      if (selectedPoses.length == 0) {
        return;
      }

      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', collection.name).where('category', '==', collection.category)
        .get(collection.id);
      var newDoc: any = doc.docs[0].data();
      if (!newDoc.poses) {
        newDoc.poses = [];
      }

      let posesInCollection = [...newDoc.poses];
      let posesToAdd = [...selectedPoses.filter(selectedPose => !posesInCollection.find(p => p.id === selectedPose.id))].map(p => ({
        id: p.id,
        path: p.path
      }));
      let poses = [...posesInCollection, ...posesToAdd];
      newDoc.poses = poses;
      await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
      this.poses.forEach(p => p.isChecked = false);
      this.poses = [...this.poses];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  search() {
    this.loading = true;
    this.firebase.functions().httpsCallable('searchPoses')({ searchText: this.searchAutocompleteCtrl.value, size: 10000 }).then(poses => {
      this.poses = poses.data;
      this.pagedPoses = poses.data.slice(0, this.pageSize);
      this.length = poses.data.length;
      this.loading = false;
    });
  }

  OnPageChange(event: PageEvent) {
    console.warn(event);
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.length) {
      endIndex = this.length;
    }

    this.pagedPoses = this.poses.slice(startIndex, endIndex);
  }

  onDescriptionChangeEvent(event, pose) {
    pose.description = event.target.value;
  }

  async delete(pose) {
    try {
      this.loading = true;
      await this.firebase.functions().httpsCallable('deleteImage')({ id: pose.id });
      this.poses = [...this.poses.filter(p => p.id !== pose.id)];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async save(pose) {
    try {
      this.loading = true;
      await this.firebase.functions().httpsCallable('updateDescription')({ id: pose.id, description: pose.description });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  checkChanged(event, pose) {
    pose.isChecked = !pose.isChecked;
  }
}
