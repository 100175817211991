import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook-data-deletion',
  templateUrl: './facebook-data-deletion.component.html',
  styleUrls: ['./facebook-data-deletion.component.scss']
})
export class FacebookDataDeletionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
