<div class='container'>
    <h1 class="app-name">Atlegras</h1>
    <div class="app-line"></div>
    <h3 class="app-description">Photography Poses, Ideas and Lighting App</h3>
    <mat-progress-spinner *ngIf='loading' mode="indeterminate"></mat-progress-spinner>
    <div *ngIf='error'>
        <div class='image-list'>
            Share has expired or does not exist
        </div>
    </div>
    <div *ngIf='!error'>
        <div class='image-list'>
            <img *ngFor='let path of paths' [src]='path | getDownloadUrl | async' />
            <div *ngIf='!loading'>
                This link expires: <b>{{expirationDateStr}}</b>
            </div>
        </div>
    </div>
</div>