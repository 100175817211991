<!-- Header -->

<header id="header-wrap" data-spy="affix" data-offset-top="55" class="grey-bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-default">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        <a class="navbar-brand" href="/">
                            <img src="assets/icon.png" alt="logo">
                        </a>
                    </div>
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse iq-font-black" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav navbar-right" id="top-menu">
                            <li class="active"><a href="#home">Home</a></li>
                            <li><a href="#video">Video</a></li>
                            <li><a href="#features">Features</a></li>
                            <li><a href="#pricing">Pricing</a></li>
                            <li><a href="#contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                    <!-- /.navbar-collapse -->
                </nav>
            </div>
        </div>
    </div>
</header>

<!-- Header End -->

<!-- Banner -->

<body>
    <section id="home" class="banner iq-box-shadow grey-bg">
        <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
            <!-- Indicators -->
            <ol class="carousel-indicators">

            </ol>
            <!-- Wrapper for slides -->
            <div class="container">
                <div class="carousel-inner" role="listbox">
                    <div class="item active">
                        <div class="banner-text">
                            <div class="row">
                                <div class="col-sm-8 col-lg-8 col-md-8">
                                    <h1 class="iq-font-black iq-tw-8" data-animation="animated fadeInLeft">
                                        <small class="iq-font-black iq-tw-5"><b class="iq-font-black">&#9679;</b> <span class="iq-font-black">Photography poses, ideas and lighting</span></small><span class="app-title"> Atlegras</span>
                                    </h1>
                                </div>
                                <div class="col-sm-4 col-lg-4 col-md-4">
                                    <div class="img-one">
                                        <img class="img-responsive feature-slide-image" data-animation="animated fadeInRight" src="/assets/images/banner/01.jpg" alt="App main screen">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Banner End -->

    <!-- Install Start -->

    <div id="download" class="iq-app white-bg">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">Install
                        </h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 text-center">
                    <a mat-raised-button (click)="onAppStoreOpen()" href="https://apps.apple.com/app/apple-store/id1559442240?pt=122830969&ct=website&mt=8" target="_blank">
                        <div class="store-button">
                            <div class="store-name">App Store</div>
                            <mat-icon class="download-icon">apple</mat-icon>
                        </div>
                    </a>
                </div>
                <div class="col-sm-6 col-md-3 re7-mt-50 text-center">
                    <a mat-raised-button href="https://play.google.com/store/apps/details?id=com.atlegras&referrer=utm_source%3DIG%26utm_medium%3Dsocial%26utm_campaign%3Dwebsite" target="_blank" (click)="onPlayStoreOpen()">
                        <div class="store-button">
                            <div class="store-name">Play Store</div>
                            <mat-icon class="download-icon">android</mat-icon>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- Install End -->

    <!-- Video Start -->
    <section id="video" class="iq-app white-bg">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">Video</h2>
                        <div class="divider"></div>
                        <p>Check out video about app features</p>
                    </div>
                </div>
            </div>
            <div class="video-player">
                <iframe src="https://www.youtube.com/embed/PpqrfuaZM3g">
            </iframe>
            </div>
        </div>
    </section>
    <!-- Video End -->

    <!-- Features Start -->
    <section id="features" class="iq-app">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">Features</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="description-box">
                    <h3 class="description-header">Explore poses and ideas</h3>
                    <div class="description-text">
                        <p>{{posesAll}}+ well organized, original and easy to repeat photography poses and ideas.</p>
                        <p>Catigories: Women, Men, Coples, Families, Kids and Special Occasions.</p>
                        <p>Collections: Head/Shoulders, Sitting, Standing, Laying, Boudoir, Creative, Fitness, Fashion, Maternity, Lifestyle, Golden Ages, Nature, Pin-Up, Retro, Sensual, Studio, Work/Business, Indoor, Urban, Strong, Sexy, Fatherhood, Motherhood,
                            Newborns, Pets, Graduation, Proposal, Wedding, Christmas, Birthday Party, Friends and more…
                        </p>
                    </div>
                </div>
                <div class="crousel-parent">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="primary" maintainAspectRatio="false" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" maxWidth="auto"
                        proportion="115">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slideUrl of exploreSlides" overlayColor="white" [hideOverlay]="false">
                            <div class="feature-slide">
                                <img [src]="slideUrl" class="feature-slide-image" alt="App screen with photography poses and ideas" />
                            </div>
                        </mat-carousel-slide>
                        
                    </mat-carousel>
                </div>
            </div>
            <div class="row">
                <div class="description-box">
                    <h3 class="description-header">NEW: AI powered collections</h3>
                    <div class="description-text">
                        <p>We've analyzed tens of millions of photos using cutting-edge AI to uncover the most popular poses. We've categorized these into new 'AI' type of collections for easy browsing and inspiration. Each pose comes with a set of variations
                            and 2 parameters: popularity and variety. Dive into the AI collections like 'Standing', 'Sitting', Laying', 'Head&Shoulders', and more!
                        </p>
                    </div>
                </div>
                <div class="crousel-parent">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="primary" maintainAspectRatio="false" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" maxWidth="auto"
                        proportion="115">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slideUrl of aiSlides" overlayColor="white" [hideOverlay]="false">
                            <div class="feature-slide">
                                <img [src]="slideUrl" class="feature-slide-image" alt="App screen with photography poses and ideas" />
                            </div>
                        </mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
            <div class="row">
                <div class="description-box">
                    <h3 class="description-header">Search for poses and ideas</h3>
                    <div class="description-text">
                        <p>Find even more poses and ideas with full-featured search engine. Click on one of the popular searches or type what you are looking for.</p>
                        <p>Couple on a couch, strong man studio, young woman on a beach, curvy woman boudoir, funny boy, creative idea for couple, man sitting on the stairs, woman sitting on a bench, woman sitting in chair, LGBTQ female couple - it's just
                            a few examples of what you can find.</p>
                    </div>
                </div>
                <div class="crousel-parent">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="primary" maintainAspectRatio="false" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" maxWidth="auto"
                        proportion="115">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slideUrl of searchSlides" overlayColor="white" [hideOverlay]="false">
                            <div class="feature-slide">
                                <img [src]="slideUrl" class="feature-slide-image" alt="App screen with search functionality" />
                            </div>
                        </mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
            <div class="row">
                <div class="description-box">
                    <div class="description-header">Create your collections</div>
                    <div class="description-text">
                        <p>Build your own collections combining poses and ideas available in the app with poses and ideas you found.</p>
                        <p>Share collections and individual poses and ideas with fellow photographers and models.</p>
                    </div>
                </div>
                <div class="crousel-parent">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="primary" maintainAspectRatio="false" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" maxWidth="auto"
                        proportion="115">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slideUrl of createSlides" overlayColor="white" [hideOverlay]="false">
                            <div class="feature-slide">
                                <img [src]="slideUrl" class="feature-slide-image" alt="App screen how to create collections" />
                            </div>
                        </mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
            <div class="row">
                <div class="description-box">
                    <div class="description-header">Try lighting setups</div>
                    <div class="description-text">
                        <p>Dozens of popular and proven to work studio lighting setups. </p>
                        <p>From basic to advanced.</p>
                        <p>Rembrandt, split, loop, rim, butterfly(paramount) and more.</p>
                    </div>
                </div>
                <div class="crousel-parent">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="primary" maintainAspectRatio="false" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" maxWidth="auto"
                        proportion="115">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slideUrl of lightingSlides" overlayColor="white" [hideOverlay]="false">
                            <div class="feature-slide">
                                <img [src]="slideUrl" class="feature-slide-image" alt="App screen with lighting setups" />
                            </div>
                        </mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
            <div class="row">
                <div class="description-box">
                    <div class="description-header">Track golden/blue hours and weather</div>
                    <div class="description-text">
                        <p>During golden hour, the sun is low on the horizon and gives an warm light. The blue hour arrives shortly before sunrise and after sunset, when the sun’s position just below the horizon produces cooler tones.</p>
                        <p>Use this to your advantage to take amazing photos.</p>
                        <p>And don’t forget to check the weather.</p>
                    </div>
                </div>
                <div class="crousel-parent">
                    <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="2000" color="primary" maintainAspectRatio="false" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false" orientation="ltr" maxWidth="auto"
                        proportion="115">
                        <mat-carousel-slide #matCarouselSlide *ngFor="let slideUrl of weatherSlides" overlayColor="white" [hideOverlay]="false">
                            <div class="feature-slide">
                                <img [src]="slideUrl" class="feature-slide-image" alt="App screen with golden and blue hours tracking" />
                            </div>
                        </mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
        </div>
    </section>
    <!-- Features End -->

    <!-- Price -->

    <section id="pricing" class="iq-app iq-price-table">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">Price</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3">
                    <div class="iq-pricing text-center">
                        <div class="iq-font-black price-title grey-bg">
                            <h1 class="iq-font-black iq-tw-7"><small>$</small>0<small>/Month</small></h1>
                            <span class="text-uppercase iq-tw-6 iq-font-black">FREE</span>
                        </div>
                        <ul>
                            <li>{{posesFree}}+ Poses & ideas</li>
                            <li>{{setupsFree}} Lighting setups</li>
                            <li>5 uploads</li>
                            <li>Golden & blue hours: your location, current or next day</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 re7-mt-50">
                    <div class="iq-pricing text-center">
                        <div class="price-title grey-bg">
                            <h1 class="iq-font-black iq-tw-7"><small>$</small>9.99<small>/Month</small></h1>
                            <span class="text-uppercase iq-tw-6 iq-font-black">PREMIUM</span>
                        </div>
                        <ul>
                            <li>{{posesAll}}+ Poses & ideas</li>
                            <li>{{setupsAll}} Lighting setups</li>
                            <li>Unlimited uploads</li>
                            <li>Golden & blue hours: any date and location</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Price END -->

    <!-- Feedback -->

    <section id="feedback" class="iq-app iq-price-table">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">Customers feedback</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-lg-3 col-md-3">
                        <div class="iq-fancy-box-02 text-center">
                            <div class="fancy-content">
                                <div class="iq-tw-6 iq-pt-20 iq-pb-10">Ashari Charlewoodzvgukbxu</div>
                                <p>My models loved it. This apps helps me to explain what I want from my models.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-md-3 re7-mt-50">
                        <div class="iq-fancy-box-02 text-center">
                            <div class="fancy-content">
                                <div class="iq-tw-6 iq-pt-20 iq-pb-10">Cruz Wynand</div>
                                <p>I see how my photos are getting better and better.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                        <div class="iq-fancy-box-02 text-center">
                            <div class="fancy-content">
                                <div class="iq-tw-6 iq-pt-20 iq-pb-10">Foreman Thibaut</div>
                                <p>Really love 3D view of lighting setups, much easier to replicate the schema.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                        <div class="iq-fancy-box-02 text-center">
                            <div class="fancy-content">
                                <div class="iq-tw-6 iq-pt-20 iq-pb-10">Walters Hickok</div>
                                <p>Really like this app, but please add more collections and professional lighting setups.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Feedback End-->

    <!-- Contact -->

    <section id="contact-us" class="iq-our-info white-bg">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title">
                        <h2 class="title iq-tw-6">Contact Us</h2>
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <ul class="info-share">
                        <li><a href="mailto:support@atlegras.com"><i class="fa fa-envelope"></i></a><span>support@atlegras.com</span></li>
                        <li><a href="https://www.instagram.com/atlegras_app/"><i class="fa fa-instagram"></i></a><span>@atlegras_app</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <!-- Contact END -->

    <section class="iq-our-info white-bg">
        <div class="row">
            <a class="service-link" href="privacy">Privacy policy</a>
        </div>
        <div class="row">
            <a class="service-link" href="termsconditions">Terms & Conditions</a>
        </div>
    </section>

    <div id="back-to-top">
        <a class="top" id="top" href="#top"><i class="fa fa-angle-double-up"></i></a>
    </div>
</body>