<div class="content" role="main">
    <mat-toolbar color="primary">
        <span>Admin Console</span>
        <span class="example-spacer"></span>
        <button mat-raised-button (click)="authService.Logout()">Logout</button>
    </mat-toolbar>
    <mat-tab-group mat-stretch-tabs class="tab-group">
        <mat-tab label="Buckets">
            <div class='flex-row'>
                <div>
                    <div class='flex-row'>
                        <button mat-button [matMenuTriggerFor]="menu">Select Bucket</button>
                        <mat-menu #menu="matMenu" class="bucket-menu">
                            <button mat-menu-item *ngFor='let bucket of buckets$ | async' (click)="selectBucket(bucket)" class="bucket-menu-button">
                            {{bucket.folderName}}({{bucket.count}})
                            </button>
                        </mat-menu>
                        <div>Total: {{totalCount$ | async}} Free: {{freePosesCount}}</div>
                    </div>
                    <div *ngIf="selectedBucket">
                        <app-bucket [bucket]="selectedBucket"></app-bucket>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Collections">
            <button mat-button [matMenuTriggerFor]="collectionCategoriesMenu">Select Collection</button>
            <mat-menu #collectionCategoriesMenu="matMenu">
                <ng-container *ngFor="let collectionCategory of collectionCategories">
                    <button class="menuItem" mat-menu-item [matMenuTriggerFor]="sub_menu">{{ collectionCategory.category }}</button>
                    <mat-menu #sub_menu="matMenu">
                        <ng-container *ngFor="let collection of getCollectionsByCategory(collectionCategory.category)">
                            <button class="menuItem" mat-menu-item (click)='selectCollection(collection)'>
                                {{ collection.name }}({{collection.poses.length}}){{collection.isAI ? ' *AI*' : ''}}{{collection.isArtisanAngle ? ' *Artisian*' : ''}}
                        </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </mat-menu>
            <div *ngIf="selectedCollection">
                <app-collections [collection]="selectedCollection"></app-collections>
            </div>
        </mat-tab>
        <mat-tab label="Search">
            <app-search></app-search>
        </mat-tab>
        <mat-tab label="Not in collection">
            <app-not-in-collection></app-not-in-collection>
        </mat-tab>
        <mat-tab label="Scripts">
            <app-scripts></app-scripts>
        </mat-tab>
    </mat-tab-group>
</div>
