<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8" />
    <title>Atlegras Facebook Data Deletion Instructions</title>
</head>

<body>
    <h1>Atlegras Facebook Data Deletion Instructions</h1>
    <p>Atlegras is a facebook app and we do not save your personal data in our server. According to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.</p>

    <p>If you want to delete your activities for Atlegras App, you can remove your information by following these steps:</p>

    <p>1. Go to your Facebook Account’s Setting & Privacy. Click “Settings”</p>

    <p>2. Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.</p>

    <p>3. Search and Click “Atlegras” in the search bar.</p>

    <p>4. Scroll and click “Remove”.</p>

    <p>5. Congratulations, you have succesfully removed your app activities.</p>
</body>

</html>