<mat-card>
    <mat-progress-spinner *ngIf='loading' mode="indeterminate"></mat-progress-spinner>
    <div class="flex-row">
        <div class="with-padding">

            <button mat-button [matMenuTriggerFor]="collectionCategoriesMenu">Add to...</button>
            <mat-menu #collectionCategoriesMenu="matMenu">
                <ng-container *ngFor="let collectionCategory of collectionCategories">
                    <button class="menuItem" mat-menu-item [matMenuTriggerFor]="sub_menu">{{ collectionCategory.category }}</button>
                    <mat-menu #sub_menu="matMenu">
                        <ng-container *ngFor="let collection of getCollectionsByCategory(collectionCategory.category)">
                            <button class="menuItem" mat-menu-item (click)='addToCollection(collection)'>
                            {{ collection.name }}({{collection.poses.length}}){{collection.isAI ? ' *AI*' : ''}}{{collection.isArtisanAngle ? ' *Artisian*' : ''}}
                         </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </mat-menu>
        </div>
    </div>
    <div *ngIf='poses'>
        <div>
            {{poses.length}} poses found
        </div>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = OnPageChange($event)">
        </mat-paginator>
        <div *ngFor='let pose of pagedPoses'>
            <div class="flex-row">
                <div>
                    <mat-checkbox [checked]="pose.isChecked" (change)="checkChanged($event, pose)">
                    </mat-checkbox>
                </div>
                <div class="img-box">
                    <img [src]='pose.imgPath | getDownloadUrl | async' />
                </div>
                <div class="with-padding flex-column">
                    <div>Path: <b>{{pose.imgPath}}</b></div>
                    <div>Folder(bucket): <b>{{pose.imgFolder}}</b></div>
                    <div>Name: <b>{{pose.imgName}}</b></div>
                    <mat-form-field class="text-field">
                        <mat-label>Description</mat-label>
                        <input matInput [value]="pose.description" (input)="onDescriptionChangeEvent($event, pose)" />
                    </mat-form-field>
                    <button mat-raised-button color="warn" (click)='delete(pose)'>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-card>