import { Pipe, PipeTransform } from '@angular/core';
import { Observable, from } from 'rxjs';
import { FirebaseService } from './services/firebase.service';

const bucket = 'photoposesmobileapp.appspot.com';

@Pipe({
  name: 'getDownloadUrl'
})
export class GetDownloadUrlPipe implements PipeTransform {

  constructor(private firebase: FirebaseService) { }

  transform(path: string, ...args: unknown[]): Observable<string> {
    // return of(`https://storage.googleapis.com/${bucket}/${encodeURIComponent(path.toLowerCase().replace('.jpeg', '-small.jpeg'))}?alt=media`);

    //return of(`https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(path.toLowerCase().replace('.jpeg', '-small.jpeg'))}?alt=media`);

    return from(this.firebase.storage().ref(path).getDownloadURL());
  }
}
