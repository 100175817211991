<mat-card>
    <mat-progress-spinner *ngIf='loading' mode="indeterminate"></mat-progress-spinner>
    <div class="flex-row">
        <div class="with-padding">
            <p><b>{{bucket.folderName}}</b></p>
        </div>
        <div class="with-padding">
            <button mat-button [matMenuTriggerFor]="collectionCategoriesMenu">Add to...</button>
            <mat-menu #collectionCategoriesMenu="matMenu">
                <ng-container *ngFor="let collectionCategory of collectionCategories">
                    <button class="menuItem" mat-menu-item [matMenuTriggerFor]="sub_menu">{{ collectionCategory.category }}</button>
                    <mat-menu #sub_menu="matMenu">
                        <ng-container *ngFor="let collection of getCollectionsByCategory(collectionCategory.category)">
                            <button class="menuItem" mat-menu-item (click)='addToCollectionSelected(collection)'>
                                {{ collection.name }}({{collection.poses.length}}){{collection.isAI ? ' *AI*' : ''}}{{collection.isArtisanAngle ? ' *Artisian*' : ''}}
                        </button>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </mat-menu>
            <button mat-raised-button color="secondary" (click)="selectAll()">Select All</button>
        </div>
    </div>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="OnPageChange($event)">
    </mat-paginator>
    <div *ngFor='let pose of pagedPoses'>
        <div class="flex-row">
            <div>
                <mat-checkbox [checked]="pose.isChecked" (change)="checkChanged($event, pose)">
                </mat-checkbox>
            </div>
            <div class="img-box">
                <img [src]='pose.imgPath | getDownloadUrl | async' />
            </div>
            <div class="with-padding">
                <div>Path: <b>{{pose.imgPath}}</b></div>
                <div>Folder(bucket): <b>{{pose.imgFolder}}</b>, Id: <b>{{pose.id}}</b>, </div>
                <div>Source: <a [href]="pose.source" target="_blank">{{pose.source}}</a></div>
                <mat-form-field class="text-field">
                    <mat-label>Description</mat-label>
                    <input matInput [value]="pose.description" (input)="onDescriptionChangeEvent($event, pose)" />
                </mat-form-field>
                <div class="flex-row">
                    <button mat-raised-button color="primary" (click)='save(pose)'>
                        Save
                    </button>
                    <button mat-raised-button color="warn" (click)='delete(pose)'>
                        Delete
                    </button>
                    <button mat-raised-button color="secondary" (click)='toggleFree(pose.id)'>
                        {{isFree(pose.id) ? "Free" : "Premium"}}
                    </button>
                    <button mat-button [matMenuTriggerFor]="collectionCategoriesMenu">Add to...</button>
                    <mat-menu #collectionCategoriesMenu="matMenu">
                        <ng-container *ngFor="let collectionCategory of collectionCategories">
                            <button class="menuItem" mat-menu-item [matMenuTriggerFor]="sub_menu">{{ collectionCategory.category }}</button>
                            <mat-menu #sub_menu="matMenu">
                                <ng-container *ngFor="let collection of getCollectionsByCategory(collectionCategory.category)">
                                    <button class="menuItem" mat-menu-item (click)='addToCollectionOne(collection, pose)'>
                            {{ collection.name }}({{collection.poses.length}})
                        </button>
                                </ng-container>
                            </mat-menu>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</mat-card>