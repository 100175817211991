import { Component, Input, OnInit } from '@angular/core';
import { PremiumPosesService } from '../services/premium-poses.service';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.scss']
})
export class BucketComponent implements OnInit {
  poses: any[] = [];
  collectionCategories: any[] = [];
  collections: any[] = [];
  loading: boolean = false;

  pagedPoses: any[];
  length: number = 0;
  pageSize: number = 100;
  pageSizeOptions: number[] = [10, 50, 100, 250, 500];
  pageEvent: PageEvent;

  _bucket: any;
  get bucket(): any {
    return this._bucket;
  }

  @Input()
  set bucket(val: any) {
    this._bucket = val;

    console.warn(val.folderName);
    this.firebase.firestore().collection("images").where('imgFolder', '==', val.folderName)
      .get().then(doc => {
        const poses = doc.docs.map(d => {
          const data: any = d.data();

          return { ...data, id: d.id };
        });
        poses.sort((a: any, b: any) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        this.poses = poses;
        this.pagedPoses = poses.slice(0, this.pageSize);
        this.length = poses.length;
      });
  }

  constructor(private firebase: FirebaseService, private premiumPoses: PremiumPosesService) { }

  ngOnInit(): void {
    this.premiumPoses.initialize();
    this.firebase.firestore().collection("collectionCategories").get()
      .then(categories => this.collectionCategories = categories.docs.map(c => c.data()));

    this.firebase.firestore().collection("collections").get()
      .then(collections => this.collections = collections.docs.map(c => c.data()).sort((a, b) => a.name.localeCompare(b.name)));
  }

  getCollectionsByCategory(category: string) {
    return this.collections.filter(c => c.category == category);
  }

  async addToCollectionSelected(collection) {
    let selectedPoses = this.poses.filter(p => p.isChecked);
    if (selectedPoses.length == 0) {
      return;
    }

    await this.addToCollection(collection, selectedPoses);
  }

  async addToCollectionOne(collection, pose) {
    await this.addToCollection(collection, [pose]);
  }

  async addToCollection(collection, selectedPoses) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('category', '==', collection.category)
        .where('name', '==', collection.name)
        .get();
      var newDoc: any = doc.docs[0].data();

      console.warn(JSON.stringify(collection));

      let posesInCollection = [...newDoc.poses];
      let posesToAdd = [...selectedPoses.filter(selectedPose => !posesInCollection.find(p => p.id === selectedPose.id))].map(p => ({
        id: p.id,
        path: p.imgPath
      }));
      let poses = [...posesInCollection, ...posesToAdd];
      newDoc.poses = poses;
      await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
      this.poses.forEach(p => p.isChecked = false);
      this.poses = [...this.poses];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  onDescriptionChangeEvent(event, pose) {
    pose.description = event.target.value;
  }

  async delete(pose) {
    try {
      this.loading = true;
      await this.firebase.functions().httpsCallable('deleteImage')({ id: pose.id });
      this.poses = [...this.poses.filter(p => p.id !== pose.id)];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async save(pose) {
    try {
      this.loading = true;
      await this.firebase.functions().httpsCallable('updateDescription')({ id: pose.id, description: pose.description });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  checkChanged(event, pose) {
    pose.isChecked = !pose.isChecked;
  }

  selectAll() {
    this.poses.forEach(p => p.isChecked = true);
  }

  isFree(id: string) {
    return this.premiumPoses.isFree(id);
  }

  async toggleFree(id: string) {
    return await this.premiumPoses.toggleFree(id);
  }

  OnPageChange(event: PageEvent) {
    console.warn(event);
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.length) {
      endIndex = this.length;
    }

    this.pagedPoses = this.poses.slice(startIndex, endIndex);
  }
}
