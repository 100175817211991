import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-data-deletion',
  templateUrl: './data-deletion.component.html',
  styleUrls: ['./data-deletion.component.scss']
})
export class DataDeletionComponent implements OnInit {

  ngOnInit(): void {
  }

  dataDeletionForm = new UntypedFormGroup({
    userId: new UntypedFormControl(''),
    email: new UntypedFormControl('')
  });

  isSubmitted = false;


  constructor(public auth: AuthService, private firebase: FirebaseService) { }

  async submitDeletionRequest() {
    try {
      await this.auth.LoginAnonymously();

      const dataDeletionRequest = {
        ...this.dataDeletionForm.value,
        dateOfRequest: new Date()
      };

      await this.firebase.firestore().collection('dataDeletion').add(dataDeletionRequest)
        .then(() => {
          this.isSubmitted = true;
        })
        .catch(error => console.error('Error submitting request: ', error));

    } catch (e) {
      console.error(e);
    }
  }

}