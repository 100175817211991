import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, from } from 'rxjs';
import { PremiumPosesService } from '../services/premium-poses.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  buckets$: Observable<any[]>;
  totalCount$: Observable<Number>;
  selectedBucket: any = null
  collectionCategories: any[] = [];
  collections: any[] = [];
  selectedCollection: any = null;
  freePosesCount: Number | undefined;

  constructor(public authService: AuthService, private firebase: FirebaseService, private premiumPoses: PremiumPosesService) { }

  ngOnInit(): void {
    this.premiumPoses.numberOfFreeImages().then(c => this.freePosesCount = c).catch(e => console.error(e));

    const firestore = this.firebase.firestore();
    const imagesCollection = firestore.collection("images");
    const collectionCategoriesCollection = firestore.collection("collectionCategories");
    const collectionsCollection = firestore.collection("collections");

    const allBuckets$ = imagesCollection.get().then((querySnapshot) => {
      const results = [];
      const dictionary = {};
      querySnapshot.forEach((doc) => {
        const image = doc.data();
        dictionary[image.imgFolder] = (dictionary[image.imgFolder] ?? 0) + 1;
      });

      for (const [folderName, count] of Object.entries(dictionary)) {
        results.push({ folderName, count });
      }

      results.sort((a: any, b: any) => (a.folderName > b.folderName) ? -1 : ((b.folderName > a.folderName) ? 1 : 0));

      return results;
    });

    const accumulator = (acc: number, curr: number): Number => acc + curr;

    this.buckets$ = from(allBuckets$.then((buckets) =>
      buckets.filter(b => b.folderName.includes('2022') || b.folderName.includes('23-') || b.folderName.includes('2023') || b.folderName.includes('2024'))
    ));

    this.totalCount$ = from(allBuckets$.then((buckets: any[]) =>
      buckets.map(b => b.count).reduce(accumulator, 0)
    ));

    collectionCategoriesCollection.get().then((querySnapshot) => {
      const categories = [];
      querySnapshot.forEach((doc) => {
        categories.push(doc.data());
      });
      this.collectionCategories = categories;
    });

    collectionsCollection.get().then((querySnapshot) => {
      const collections = [];
      querySnapshot.forEach((doc) => {
        collections.push(doc.data());
      });
      this.collections = collections.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  selectBucket(bucket: any) {
    this.selectedBucket = bucket;
  }

  getCollectionsByCategory(category: string) {
    return this.collections.filter(c => c.category == category);
  }

  selectCollection(collection: any) {
    this.selectedCollection = collection;
  }
}
