import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FirebaseService } from './services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard {

  constructor(private router: Router, private firebase: FirebaseService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const userAgent = navigator.userAgent || navigator.vendor;
    const params = window.location.search;

    const campaign = route.params['campaign'] ?? 'websiteRedirect';
    // console.log('Redirecting to', campaign);
    var platform = 'Desktop';
    var url = "https://atlegras.com" + params;
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      // iOS
      url = `https://apps.apple.com/app/apple-store/id1559442240?pt=122830969&ct=${campaign}&mt=8` + params;
      platform = 'iOS';
    } else if (/android/i.test(userAgent)) {
      // Android
      url = `https://play.google.com/store/apps/details?id=com.atlegras&referrer=utm_source%3DIG%26utm_medium%3Dsocial%26utm_campaign%3D${campaign}` + params;
      platform = 'Android';
    }

    try {
      await this.firebase.functions().httpsCallable('redirect')({ dateTime: new Date().getTime() / 1000, platform, campaign }).then((result) => console.log(result));
    } catch (e) {
      console.error(e);
    }
    // console.log('Redirecting to', platform, campaign);
    window.location.href = url;

    return false;
  }
}