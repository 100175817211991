import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';

@Injectable({
    providedIn: 'root',
})
export class PremiumPosesService {

    private initialized = false;
    private initializing = false;
    private freeImages;

    constructor(private firebase: FirebaseService) {

    }

    public async initialize() {
        if (this.initializing) {
            await timer(100).pipe(take(1)).toPromise();
            await this.initialize();

            return;
        }

        if (!this.initialized) {
            console.error('initializing');
            this.initializing = true;
            const freeImagesData = (await this.firebase.firestore().collection("freeImages").doc('free').get()).data();
            this.freeImages = new Set(freeImagesData['images']);
            this.initialized = true;
            this.initializing = false;
        }
    }

    public async numberOfFreeImages() {
        await this.initialize();

        return this.freeImages.size;
    }

    public isFree(id: string) {
        return this.freeImages.has(id);
    }

    public async toggleFree(id: string) {
        console.error('toggling' + id);

        console.error(this.freeImages.size);
        if (this.freeImages.has(id)) {
            console.error('has');
            this.freeImages.delete(id);
        } else {
            console.error('add');
            this.freeImages.add(id);
        }
        console.error(this.freeImages.size);

        await this.firebase.firestore().collection("freeImages").doc('free').update({
            images: Array.from(this.freeImages.values()),
        });
    }
}