import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component'
import { HomeComponent } from './home/home.component';

import { LoginComponent } from './login/login.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatCarouselModule } from '@magloft/material-carousel';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete'

import { BucketComponent } from './bucket/bucket.component';
import { GetDownloadUrlPipe } from './get-download-url.pipe';
import { CollectionsComponent } from './collections/collections.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { SearchComponent } from './search/search.component';
import { ShareComponent } from './share/share.component';
import { NotInCollectionComponent } from './not-in-collection/not-in-collection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { FacebookDataDeletionComponent } from './facebook-data-deletion/facebook-data-deletion.component';
import { RedirectGuard } from './redirect-guard';
import { DataDeletionComponent } from './data-deletion/data-deletion.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'termsconditions', component: TermsconditionsComponent },
  { path: 'data-deletion', component: FacebookDataDeletionComponent },
  { path: 'request-data-deletion', component: DataDeletionComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'admin/login', component: LoginComponent },
  { path: 'share/:id', component: ShareComponent },
  {
    path: 'app',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
  },
  {
    path: 'app/:campaign',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
  },
]

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    HomeComponent,
    LoginComponent,
    BucketComponent,
    GetDownloadUrlPipe,
    CollectionsComponent,
    ScriptsComponent,
    SearchComponent,
    ShareComponent,
    NotInCollectionComponent,
    PrivacyComponent,
    TermsconditionsComponent,
    FacebookDataDeletionComponent,
    DataDeletionComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    NoopAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatGridListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCarouselModule.forRoot(),
  ],
  providers: [AuthService, RedirectGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
