<div class="data-deletion-form" *ngIf="!isSubmitted; else submittedMessage">
    <h2>Data Deletion Request</h2>
    <form [formGroup]="dataDeletionForm" (ngSubmit)="submitDeletionRequest()">
        <label for="userId">User ID:</label>
        <input type="text" id="userId" formControlName="userId">

        <label for="email">Email:</label>
        <input type="email" id="email" formControlName="email">

        <button type="submit">Request Data Deletion</button>
    </form>
    <p>Note: Please ensure you also delete your account in the Atlegras app. Data will be deleted within 90 days.</p>
</div>

<ng-template #submittedMessage>
    <div class="submission-success">
        <h3>Your data deletion request has been submitted successfully.</h3>
        <p>We will process your request within 90 days. Please remember to also delete your account in the Atlegras app.</p>
    </div>
</ng-template>