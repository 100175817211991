<style>
    h2 {
        margin: 8px 0;
    }

    .content {
        display: flex;
        margin: 20px;
        flex-direction: column;
        align-items: center;
    }

    .textbox {
        margin: 10px 0;
    }
</style>

<div class="content">
    <div class="displayTableCell">

        <div class="authBlock">
            <h2>Login</h2>
            <div class="formGroup" class="textbox">
                <input matInput type="text" class="form-control" placeholder="Username" #userName required>
            </div>

            <div class="formGroup" class="textbox">
                <input matInput type="password" class="form-control" placeholder="Password" #userPassword required>
            </div>

            <div class="formGroup">
                <button mat-raised-button color="primary"
                    (click)="authService.Login(userName.value, userPassword.value)">Login</button>
            </div>
        </div>
    </div>