<mat-card>
    <mat-progress-spinner *ngIf='loading' mode="indeterminate"></mat-progress-spinner>
    <div>
        <div class='flex-row'>
            <button mat-raised-button color="warn" [disabled]='loading' (click)='cleanupCollections()'>Cleanup
                collections</button>
            <div class="with-padding">Iterates over all collections and removes poses that was deleted</div>
        </div>

        <div class='flex-row'>
            <button mat-raised-button color="warn" [disabled]='loading' (click)='pickFreeImages()'>Pick free
                images</button>
            <div class="with-padding">Ramdomly picks free images</div>
        </div>
    </div>
</mat-card>