import { Injectable, NgZone } from '@angular/core';

import { Router } from "@angular/router";
import { FirebaseService } from '../services/firebase.service';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userState: any;

  constructor(public firebase: FirebaseService,
    public router: Router,
    public ngZone: NgZone) {
    this.firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.userState = user;
        localStorage.setItem('user', JSON.stringify(this.userState));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  Login(email, password) {
    return this.firebase.auth().signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (!this.isAdmin(result.user.uid)) {
          this.Logout();

          return;
        }

        this.ngZone.run(() => {
          this.router.navigate(['admin']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  Logout() {
    return this.firebase.auth().signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['admin/login']);
    })
  }

  LoginAnonymously() {
    return this.firebase.auth().signInAnonymously();
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  SetUserData(user) {
    const userRef = this.firebase.firestore().doc(`users/${user.uid}`);
    const userState: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userState, {
      merge: true
    })
  }

  isAdmin(userId): boolean {
    return userId == 'Q6eTZT0uusekU3aCBWISrw9dUlH2' //maxgorch@gmail.com
      || userId == 'kLydoc3D2iPxKh3gpEJkH7Jos4d2' //tereschenkovv@gmail.com
      || userId == '9MppHWipLQRNonW2woBnKdNJW9G3' //y.k.yakovlieva@gmail.com
      || userId == 'ou6l5Gb8WIcmOs0uhmjTehAxwMk1' //alicezenkina@gmail.com
      ;
  }
}
