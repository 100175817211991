import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-scripts',
  templateUrl: './scripts.component.html',
  styleUrls: ['./scripts.component.scss']
})
export class ScriptsComponent implements OnInit {
  loading: boolean = false;
  cleanUpStarted: boolean = false;

  constructor(private firebase: FirebaseService) { }

  ngOnInit(): void {
  }

  async cleanupCollections() {
    this.loading = true;
    this.cleanUpStarted = false;
    console.log('cleanupCollections started');
    try {
      await this.firebase.firestore().collection("collections").get().then(
        collections => {
          if (this.cleanUpStarted) {
            return;
          }

          this.cleanUpStarted = true;
          this.cleanupCollectionsInternal(collections.docs.map(c => c.data()));
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  async cleanupCollectionsInternal(collections) {
    try {
      for (const collection of collections) {
        let newPoses = [];
        let posesRemoved = 0;

        for (let pose of (collection as any).poses) {
          try {
            await this.firebase.storage().ref((pose as any).path).getDownloadURL();
            const imageDocs = await this.firebase.firestore().collection("images").where('imgPath', '==', pose.path).get();

            if (imageDocs.docs.length > 0) {
              newPoses.push(pose);
            }
          } catch (error) {
            posesRemoved++;
            console.log(`Removing ${(pose as any).path}`);
          }
        }

        if (posesRemoved) {
          const doc = await this.firebase.firestore().collection("collections").where('name', '==', collection.name).where('category', '==', collection.category)
            .get(collection.id);
          var newDoc: any = doc.docs[0].data();
          newDoc.poses = newPoses;
          await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
        }

        console.log(`${posesRemoved} poses removed fom ${collection.category}: ${collection.name}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
      alert("Completed!!!");
    }
  }

  pickFreeImages() {
    const FREE_POSES_IN_COLLECTION = 3;
    const FREE_POSES_PERCENT = 20;
    this.loading = true;
    console.log('pickFreeImages started');
    const freeImages = [];
    try {
      this.firebase.firestore().collection("collections").get().then(
        collections => {
          for (const collection of collections.docs.map(c => c.data())) {
            let freePosesInCollection = 0;
            for (let pose of (collection as any).poses) {
              if (!freeImages.includes(pose.id)) {
                freeImages.push(pose.id);
              }
              freePosesInCollection++;
              if (freePosesInCollection >= FREE_POSES_IN_COLLECTION) {
                break;
              }
            }
          }
          console.log('Processing collections completed');
          console.log('Picked ' + freeImages.length + ' images');

          this.firebase.firestore().collection("images").get().then(
            async images => {
              for (const image of images.docs.map(i => i.data())) {
                const imgName = (image as any).imgName;
                const id = imgName.split('.')[0];

                if (!freeImages.includes(id) && Math.floor(Math.random() * 100) < FREE_POSES_PERCENT) {
                  freeImages.push(id);
                }
              }

              console.log('Processing images completed');
              console.log('Picked ' + freeImages.length + ' images');

              await this.firebase.firestore().collection("freeImages").doc('free').update({
                images: freeImages
              });

              console.log('pickFreeImages completed');
              this.loading = false;
            }
          );
        }
      );
    } catch (e) {
      console.error(e);
    }
  }
}
