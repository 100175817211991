import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  loading: boolean = true;
  error: boolean = false;
  id: string;
  paths: String[] = [];
  expirationDateStr: String;

  private sub: any;

  constructor(private route: ActivatedRoute, public auth: AuthService, private firebase: FirebaseService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadData();
    });
  }

  async loadData() {
    try {
      await this.auth.LoginAnonymously();
      var sharesSnapshot = await this.firebase.firestore().collection('shares').where('id', '==', this.id).where('expirationDate', '>=', new Date()).get();

      if (sharesSnapshot.docs.length === 0) {
        this.loading = false;
        this.error = true;

        return;
      }

      this.paths = (sharesSnapshot.docs[0].data()['poses'] as any[]).map(p => p.path);
      console.log(sharesSnapshot.docs[0].data()['expirationDate'].seconds);
      let expidationDate = new Date(sharesSnapshot.docs[0].data()['expirationDate'].seconds * 1000);

      console.log(expidationDate);
      this.expirationDateStr = expidationDate.toDateString();
      console.log(this.expirationDateStr);
      this.loading = false;
      this.error = false;
    } catch (e) {
      //TODO: Log error to BigQuery, not to the console
      console.error(e);

      this.loading = false;
      this.error = true;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
