import { Component, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { combineLatest, from } from 'rxjs';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-not-in-collection',
  templateUrl: './not-in-collection.component.html',
  styleUrls: ['./not-in-collection.component.scss']
})
export class NotInCollectionComponent implements OnInit {
  loading: boolean = false;
  poses: any[] = null;
  collectionCategories: any[] = [];
  collections: any[] = [];

  pagedPoses: any[];
  length: number = 0;
  pageSize: number = 100;
  pageSizeOptions: number[] = [10, 50, 100, 250, 500];
  pageEvent: PageEvent;

  constructor(private firebase: FirebaseService) { }

  ngOnInit(): void {
    this.loading = true;
    this.firebase.firestore().collection("collectionCategories").get()
      .then(categories => this.collectionCategories = categories.docs.map(c => c.data()));

    combineLatest([
      this.firebase.firestore().collection("collections").get().then(collections => collections.docs.map(c => c.data())),
      this.firebase.firestore().collection("images").get().then(images => images.docs.map(i => i.data())),
    ]).subscribe(
      ([collections, images]) => {
        this.collections = collections;
        const imagesInCollections = new Set();

        for (let collection of collections) {
          for (let pose of (collection as any).poses) {
            imagesInCollections.add(pose.path);
          }
        }

        const posesNotInCollection = [];
        for (let image of images) {
          if (!imagesInCollections.has((image as any).imgPath)) {
            posesNotInCollection.push(image);
          }
        }

        this.poses = posesNotInCollection;
        this.pagedPoses = this.poses.slice(0, this.pageSize);
        this.length = this.poses.length;
        this.loading = false;
      }
    );

    this.firebase.firestore().collection("collections").get()
      .then(collections => {
        this.collections = collections.docs.map(c => c.data()).sort((a, b) => a.name.localeCompare(b.name));
      });

  }

  getCollectionsByCategory(category: string) {
    return this.collections.filter(c => c.category == category);
  }

  OnPageChange(event: PageEvent) {
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.length) {
      endIndex = this.length;
    }

    this.pagedPoses = this.poses.slice(startIndex, endIndex);
  }

  async addToCollection(collection) {
    try {
      let selectedPoses = this.poses.filter(p => p.isChecked);
      if (selectedPoses.length == 0) {
        return;
      }

      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', collection.name).where('category', '==', collection.category)
        .get(collection.id);
      var newDoc: any = doc.docs[0].data();
      if (!newDoc.poses) {
        newDoc.poses = [];
      }

      let posesInCollection = [...newDoc.poses];
      let posesToAdd = [...selectedPoses.filter(selectedPose => !posesInCollection.find(p => p.path === selectedPose.imgPath))].map(p => ({
        id: this.idFromImgName(p.imgName),
        path: p.imgPath
      }));
      let poses = [...posesInCollection, ...posesToAdd];
      newDoc.poses = poses;
      await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
      this.poses.forEach(p => p.isChecked = false);
      this.poses = [...this.poses];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  checkChanged(event, pose) {
    pose.isChecked = !pose.isChecked;
  }

  private idFromImgName(imgName: string) {
    const splitted = imgName.split('.');
    splitted.pop();

    return splitted.join('.');
  }

  async delete(pose) {
    try {
      this.loading = true;
      const imageDocs = await this.firebase.firestore().collection("images").where('imgPath', '==', pose.imgPath).get();

      console.log(pose.imgPath);
      console.log(imageDocs.docs.length);

      await this.firebase.functions().httpsCallable('deleteImage')({ id: imageDocs.docs[0].id });
      this.poses = [...this.poses.filter(p => p.id !== pose.id)];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
