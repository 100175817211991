import { Component, Input, OnInit } from '@angular/core';
import { PremiumPosesService } from '../services/premium-poses.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {

  loading: boolean = false;

  _collection: any;
  get collection(): any {
    return this._collection;
  }

  @Input()
  set collection(val: any) {
    this._collection = val;
  }

  constructor(private firebase: FirebaseService, private premiumPoses: PremiumPosesService) { }

  ngOnInit(): void {
    this.premiumPoses.initialize();
  }

  async delete(pose) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
        .get(this.collection.id);
      var newDoc: any = doc.docs[0].data();
      var poses = [...newDoc.poses.filter(p => p.id !== pose.id)];
      newDoc.poses = poses;
      await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
      this.collection.poses = this.collection.poses.filter(p => poses.find(pp => pp.id == p.id));
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async up(pose) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
        .get(this.collection.id);
      var newDoc: any = doc.docs[0].data();

      for (let i = 0; i < newDoc.poses.length; i++) {
        if (newDoc.poses[i].id === pose.id) {
          if (i === 0) {
            break;
          }

          const tmp = newDoc.poses[i];
          newDoc.poses[i] = newDoc.poses[i - 1];
          newDoc.poses[i - 1] = tmp;
          await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
          const tmp2 = this.collection.poses[i];
          this.collection.poses[i] = this.collection.poses[i - 1];
          this.collection.poses[i - 1] = tmp2;
          break;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async up10(pose) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
        .get(this.collection.id);
      var newDoc: any = doc.docs[0].data();

      for (let i = 0; i < newDoc.poses.length; i++) {
        if (newDoc.poses[i].id === pose.id) {
          if (i === 0) {
            break;
          }

          newDoc.poses.splice(i - 10, 0, ...newDoc.poses.splice(i, 1))
          await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
          this.collection.poses.splice(i - 10, 0, ...this.collection.poses.splice(i, 1));
          break;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async down(pose) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
        .get(this.collection.id);
      var newDoc: any = doc.docs[0].data();

      for (let i = 0; i < newDoc.poses.length; i++) {
        if (newDoc.poses[i].id === pose.id) {
          if (i === newDoc.poses.length - 1) {
            break;
          }

          const tmp = newDoc.poses[i];
          newDoc.poses[i] = newDoc.poses[i + 1];
          newDoc.poses[i + 1] = tmp;
          await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
          const tmp2 = this.collection.poses[i];
          this.collection.poses[i] = this.collection.poses[i + 1];
          this.collection.poses[i + 1] = tmp2;
          break;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async down10(pose) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
        .get(this.collection.id);
      var newDoc: any = doc.docs[0].data();

      for (let i = 0; i < newDoc.poses.length; i++) {
        if (newDoc.poses[i].id === pose.id) {
          if (i === 0) {
            break;
          }

          newDoc.poses.splice(i + 10, 0, ...newDoc.poses.splice(i, 1))
          await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
          this.collection.poses.splice(i + 10, 0, ...this.collection.poses.splice(i, 1));
          break;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  isFree(id: string) {
    return this.premiumPoses.isFree(id);
  }

  async toggleFree(id: string) {
    return await this.premiumPoses.toggleFree(id);
  }

  async shuffle() {
    this.loading = true;
    const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
      .get(this.collection.id);
    var newDoc: any = doc.docs[0].data();
    //this.collection.poses.sort(() => .5 - Math.random());

    newDoc.poses.sort(() => .5 - Math.random());
    await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
    const updatedDoc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
      .get(this.collection.id);
    this.collection = updatedDoc.docs[0].data();

    this.loading = false;
  }

  async first(pose) {
    try {
      this.loading = true;
      const doc = await this.firebase.firestore().collection("collections").where('name', '==', this.collection.name).where('category', '==', this.collection.category)
        .get(this.collection.id);
      var newDoc: any = doc.docs[0].data();

      newDoc.poses = [pose, ...newDoc.poses.filter(p => p.id != pose.id)]
      await this.firebase.firestore().collection("collections").doc(doc.docs[0].id).update(newDoc);
      this.collection.poses = [pose, ...newDoc.poses.filter(p => p.id != pose.id)];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
